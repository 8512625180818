import react, { useState, useEffect } from "react";
import { Layout, Menu, message, Space } from "antd";
import {
  SettingFilled,
  DollarCircleFilled,
  ExceptionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";

import Favicon from "../../../images/favicon.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SiderBar = ({ toggle, collapsed }) => {
  const navigate = useNavigate();
  const info = new API.Info();

  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = (payload) => {
    getDataManager(info?.getInfo, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        setSettings(x?.data);
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
      }
    });
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
      <div className="laststand-logo">
        {collapsed ? (
          <img className="collapsed-img" src={settings?.logo_url} alt="" />
        ) : (
          <img className="not-collapsed-img" src={settings?.logo_url} alt="" />
        )}
      </div>
      <Menu theme="dark" defaultSelectedKeys={["1a"]} mode="inline">
        <SubMenu key="1" icon={<SettingFilled />} title="Content Management">
          <Menu.Item
            key="1a"
            onClick={() => navigate("/content-management/videos")}
          >
            Videos
          </Menu.Item>
          <Menu.Item
            key="1b"
            onClick={() => navigate("/content-management/live")}
          >
            Live
          </Menu.Item>
          <Menu.Item
            key="1c"
            onClick={() => navigate("/content-management/announcement")}
          >
            Announcement
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="2"
          icon={<DollarCircleFilled />}
          onClick={() => navigate("/donations")}
        >
          Donations
        </Menu.Item>
        <SubMenu key="3" icon={<ExceptionOutlined />} title="Administration">
          <Menu.Item key="3a" onClick={() => navigate("/administration/users")}>
            Users
          </Menu.Item>
          <Menu.Item
            key="3b"
            onClick={() => navigate("/administration/global-settings")}
          >
            Global Settings
          </Menu.Item>
          <Menu.Item key="3c" onClick={() => navigate("/privacy-policy")}>
            Privacy Policy
          </Menu.Item>
          <Menu.Item
            key="3d"
            onClick={() => navigate("/administrations/terms")}
          >
            Terms and Conditions
          </Menu.Item>
        </SubMenu>
      </Menu>
      {collapsed ? (
        <p className="copyright-text">©</p>
      ) : (
        <p className="copyright-text">Copyright © 2022 {settings?.site_name}</p>
      )}
    </Sider>
  );
};

export default SiderBar;
