/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Row, Col, Card, Typography } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Spinner from "../../common/spinner";

import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";
import { setUserData } from "../../../utils/redux/actions";

import authImg from "../../../images/auth.png";
import logo from "../../../images/favicon.svg";

import "./styles.scss";

const { Title } = Typography;

const Login = () => {
  const [form] = Form.useForm();

  const auth = new API.Auth();
  const info = new API.Info();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  console.log(settings);

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleRefresh = () => {
    window.location.reload(false);
  };

  const onLogin = (payload) => {
    getDataManager(auth?.loginUser, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        if (x?.data?.token) {
          message.success({
            content: "You have successfully logged in",
            duration: 3,
          });
          localStorage.setItem(
            "last-stand-admin-panel-user-data",
            JSON.stringify(x?.data)
          );
          dispatch(setUserData(x?.data));
          // navigate("/dashboard");
          handleRefresh();
        }
      } else {
        const error = getErrorMessage(x?.errors);
        dispatch(setUserData({}));
        localStorage.removeItem("last-stand-admin-panel-user-data");
        message.error({
          content: x?.message || error || "Error ocurred while logging in",
          duration: 3,
        });
      }
    });
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  const fetchInfo = (payload) => {
    getDataManager(info?.getInfo, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        setSettings(x?.data);
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
      }
    });
  };

  return (
    <Row>
      <Col span={12} className="login-right-section">
        <img src={authImg} alt="" />
        <div className="main-tagline">
          Welcome! A few click and access to dashboard
        </div>
        <div className="sub-tagline">Manage all your system in one place</div>
      </Col>
      <Col span={12} className="login-left-section">
        <Card className="login-card" bordered={false}>
          {loading && <Spinner />}
          <div className="logo-container">
            <img src={settings?.logo_url} alt="" className="logo" />
          </div>
          <Title level={4} className="login-title">
            Sign In
          </Title>
          <Form form={form} onFinish={onLogin} scrollToFirstError>
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="mobile" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
              className="password-field"
            >
              <Input.Password
                placeholder="Password"
                visible={true}
                size="large"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="forgot-password-btn-container">
              <Button
                type="link"
                onClick={handleForgotPassword}
                style={{ padding: 0 }}
              >
                Forgot Password ?
              </Button>
            </div>
            <Form.Item>
              <Button htmlType="submit" size="large" type="primary">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
